import { CSSProperties, useEffect, useState } from 'react';
import { useMobile } from '@ui/hooks';
import { LoaderProvider } from '@ui/contexts';
import {
  Drawer,
  DrawerContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Button,
} from '@ui/components';
import { OnboardingModalCTA } from './_components/onboarding-modal-cta';
import { OnboardingContent } from './_components/onboarding-content';
import { OnboardingState } from '../src/services/typings';
import useFilterChecklist from './_hooks/useFllterChecklist';

interface Props {
  className?: string;
  style?: CSSProperties;
  userType?: 'creator' | 'fan';
  creator_id: string;
  disableAbsolute?: boolean;
  shouldChecklistAnimate: boolean;
  onAnimationAction: (value?: boolean) => void;
  state: OnboardingState;
  onTabChange?: ((tab: string) => void | undefined) | undefined;
  currentChecklistEvent?: string | undefined;
  replacePlaceholders?:
    | ((route: string, creatorId: string, communitySlug?: string) => string)
    | undefined;
}
export const ChecklistModal = ({ userType = 'fan', ...props }: Props) => {
  const [open, setOpen] = useState(false);
  const [justClosed, setJustClosed] = useState(false);
  const isMobile = useMobile();
  const { hasIncompleteTask, progress } = useFilterChecklist(props?.state, userType);
  // const areTasksCompleted = props.state?.progress?.tasklist?.every((task) => task.completed);
  const stayVisible = sessionStorage.getItem('onboarding-stay-visible') === props.creator_id;

  useEffect(() => {
    if (props.state?.progress?.progress?.trigger === false) {
      setOpen(true);
      props.state.patchChecklistTrigger({
        creator_id: props?.creator_id,
        value: true,
        user_type: userType,
        skip: !userType,
      });
    }
  }, [props?.state?.progress?.progress?.trigger, props?.creator_id]);

  useEffect(() => {
    if (props.state?.checklistPatchMutationTriggerAt && !hasIncompleteTask) {
      sessionStorage.setItem('onboarding-stay-visible', props.creator_id);
    }
  }, [props.state?.checklistPatchMutationTriggerAt, hasIncompleteTask]);

  const onClose = () => {
    setOpen(false);
    setJustClosed(true);

    setTimeout(() => {
      setJustClosed(false);
    }, 1000);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose();
    } else {
      setOpen(true);
    }
  };

  if (!hasIncompleteTask && !props?.disableAbsolute && !stayVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <LoaderProvider>
        <Drawer open={open} onOpenChange={handleOpenChange}>
          <DialogTrigger className="space-y-0">
            {props?.disableAbsolute ? (
              <Button
                type="primary"
                className="flex w-min gap-2 text-xs min-w-[150px] max-h-[35px] rounded-md">
                View Checklist
              </Button>
            ) : (
              <OnboardingModalCTA
                {...props}
                state={props?.state}
                progress={progress}
                triggerCloseAnim={justClosed}
                creatorId={props?.creator_id}
                shouldChecklistAnimate={props?.shouldChecklistAnimate}
                onAnimationAction={props?.onAnimationAction}
                currentChecklistEvent={props?.currentChecklistEvent}
              />
            )}
          </DialogTrigger>
          <DrawerContent
            hideDragHandle
            className="scrollbar-none bg-white focus-visible:outline-none mt-0 rounded-t-lg sm:rounded-lg p-1.5 m-0 overflow-hidden">
            <DialogTitle />
            <OnboardingContent
              state={props?.state}
              closeModal={onClose}
              onTabChange={props?.onTabChange}
              replacePlaceholders={props?.replacePlaceholders}
              userType={userType}
            />
          </DrawerContent>
        </Drawer>
      </LoaderProvider>
    );
  }

  return (
    <LoaderProvider>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogTrigger className="space-y-0">
          {props?.disableAbsolute ? (
            <Button
              type="primary"
              className="flex w-min gap-2 text-xs min-w-[150px] max-h-[35px] rounded-md">
              View Checklist
            </Button>
          ) : (
            <OnboardingModalCTA
              {...props}
              state={props?.state}
              progress={progress}
              triggerCloseAnim={justClosed}
              creatorId={props?.creator_id}
              shouldChecklistAnimate={props?.shouldChecklistAnimate}
              onAnimationAction={props?.onAnimationAction}
              currentChecklistEvent={props?.currentChecklistEvent}
            />
          )}
        </DialogTrigger>
        <DialogContent
          className="scrollbar-none rounded-lg sm:rounded-sm m-0 max-h-[75vh] min-w-[900px] bg-white overflow-hidden p-0"
          showCloseButton={false}>
          <DialogTitle className="hidden" />
          <OnboardingContent
            state={props?.state}
            closeModal={onClose}
            onTabChange={props?.onTabChange}
            replacePlaceholders={props?.replacePlaceholders}
            userType={userType}
          />
        </DialogContent>
      </Dialog>
    </LoaderProvider>
  );
};
