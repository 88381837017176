import { onboardingApiService } from '@onboarding/services';
import { authQuery } from '../config/query.config';

export const onboardingApi = onboardingApiService(authQuery);
export const {
  useGetOnboardingChecklistQuery,
  useGetOnboardingProgressQuery,
  usePatchOnboardingTaskMutation,
  usePatchOnboardingTriggerMutation,
} = onboardingApi;
